import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./tenant/assets/style/style.scss";
import { config } from "./tenant/config";

const root = ReactDOM.createRoot(document.getElementById("root"));

const getTheameColor = async () => {
  let configData = await fetch(`${config.localApiUrl}/api/loadconfig`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
  configData = await configData.json();
  localStorage.setItem("configData", JSON.stringify(configData.data));
  window.location.reload();
};

document.title = localStorage.getItem("configData")
  ? JSON.parse(localStorage.getItem("configData")).websiteName
  : "Sumo Ordering";
document.getElementById("favicon").href = localStorage.getItem("configData")
  ? `${config.localApiUrl}/tenancy/assets/${
      JSON.parse(localStorage.getItem("configData")).favicon
    }`
  : getTheameColor();
document.documentElement.style.setProperty(
  "--teal-bg",
  localStorage.getItem("configData") &&
    JSON.parse(localStorage.getItem("configData")).colorPalatte
);
document.documentElement.style.setProperty(
  "--text-teal",
  localStorage.getItem("configData") &&
    JSON.parse(localStorage.getItem("configData")).colorPalatte
);
document.documentElement.style.setProperty("--text-white", "#fff");
document.documentElement.style.setProperty("--white-bg", "#fff");
document.documentElement.style.setProperty("--text-black", "#000");
document.documentElement.style.setProperty(
  "--secondary-color",
  localStorage.getItem("configData") &&
    JSON.parse(localStorage.getItem("configData")).SecondaryColorPalatte
);

let ogImage = document.querySelector('meta[property="og:image:secure_url"]');
ogImage = document.createElement("meta");
ogImage.setAttribute("property", "og:image:secure_url");
document.head.appendChild(ogImage);
ogImage.content =
  localStorage.getItem("configData") &&
  `${config.localApiUrl}/tenancy/assets/${
    JSON.parse(localStorage.getItem("configData")).favicon
  }`;

// const updateAppleTouchIcon = (url) => {
// let appleTouchIcon = document.querySelector('link[rel="apple-touch-icon"]');
// const url = localStorage.getItem("configData")
// ? `${config.localApiUrl}/tenancy/assets/${
//     JSON.parse(localStorage.getItem("configData")).favicon
//   }`: getTheameColor();
// if (appleTouchIcon) {
//   appleTouchIcon.href = url
// } else {
//   // Create a new apple-touch-icon element if not found
//   const newAppleTouchIcon = document.createElement("link");
//   newAppleTouchIcon.rel = "apple-touch-icon";
//   newAppleTouchIcon.href = url;
//   document.head.appendChild(newAppleTouchIcon);
// }
// };
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
