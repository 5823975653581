import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderLogo from "../assets/images/header-logo.svg";
import footerlogo from "../assets/images/footer-logo.svg";
import heart from "../assets/images/heart.svg";
// import applepay from "../assets/images/apple-pay.svg";
import usericon from "../assets/images/user-icon.svg";
import ordericon from "../assets/images/order-icon.svg";
import promotionicon from "../assets/images/promotion-icon.svg";
import giftmenuicon from "../assets/images/gift-menu-icon.svg";
import arrowwhite from "../assets/images/arrow-white.svg";
import carticon from "../assets/images/cart_icon.svg";
import { config } from "../config";
import { useDispatch, useSelector } from "react-redux";
import { configActions } from "../_actions";
import { status } from "../_constants";
import CustomLoader from "./CustomeLoader";
import { alert } from "../_utilities";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.cartList.cart_list_data);
  const conData = useSelector((state) => state.config);
  let userData = localStorage.getItem("userData");

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const [user, setUser] = useState();
  // const [isLoader, setIsLoader] = useState(false);
  const [configData, setConfigData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(configActions.loadConfigData());
    setLoading(true);
  }, []);

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    setUser(storedUserData ? JSON.parse(storedUserData) : null);
  }, []);

  useEffect(() => {
    if (conData && conData?.config_status == status?.SUCCESS) {
      setConfigData(conData?.config_data);
      localStorage.setItem("configData", JSON.stringify(conData?.config_data));
      setLoading(false);
    }
  }, [conData?.config_data]);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };
  // const redirectTo = (page) => {
  //   if (page && page === "signup") {
  //     setIsMenuOpen(false);
  //     navigate("/");
  //   } else if (page && page === "login") {
  //     setIsMenuOpen(false);
  //     navigate("/login");
  //   }
  // };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const sidebarRef = useRef(null);
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsopen(false);
    }
  };

  const currentUrl = window.location.href;

  // Construct the WhatsApp share URL
  const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(currentUrl)}`;

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    setUser(null);
    setIsopen(false);
    navigate("/");
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          image: window.location.href,
          title: "Check this out!",
          text: "Visit this amazing website!",
          url: window.location.href,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      alert.error("Sharing is not supported on this device.");
    }
  };

  return (
    <>
      <Helmet>
        <meta name="description" content="Order Your Takeaway" />
        {configData && configData.headerLogo ? (
          <meta
            property="og:image"
            content={`${config.localApiUrl}/tenancy/assets/${configData.headerLogo}`}
          />
        ) : (
          ""
        )}
      </Helmet>
      <header className="header-area">
        {/* <CustomLoader loading={loading} />
      {!loading && ( */}
        <div className="container">
          <div className="row flex-wrap-nowrap">
            <div className="col-lg-6 col-6">
              <div className="header-left">
                <Link to="/">
                  {configData && configData.headerLogo ? (
                    <img
                      src={`${config.localApiUrl}/tenancy/assets/${configData.headerLogo}`}
                      alt="Header Logo"
                    />
                  ) : (
                    ""
                  )}
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="header-right">
                <div className="cart-icon">
                  {
                    <Link
                      onClick={() =>
                        cartData &&
                        cartData.cartData &&
                        cartData.cartData.length <= 0 &&
                        alert.error("Please add items to cart first.!")
                      }
                      to={
                        cartData &&
                        cartData.cartData &&
                        cartData.cartData.length > 0
                          ? "/cart"
                          : "/menu"
                      }
                    >
                      <img src={carticon} alt="" />
                      {cartData &&
                        cartData.cartData &&
                        cartData.cartData.length > 0 && (
                          <span>{cartData.cartData.length}</span>
                        )}
                    </Link>
                  }
                </div>
                <button
                  className={`menu-button ${isOpen == true ? "open" : ""}`}
                  onClick={ToggleSidebar}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <nav
                  ref={sidebarRef}
                  className={`menu ${isOpen == true ? "open" : ""}`}
                >
                  <div className="sd-header">
                    <div className="menu-close" onClick={ToggleSidebar}>
                      <img src={arrowwhite} alt="" />
                    </div>
                  </div>
                  {userData == null ? (
                    <div className="hide-desk">
                      <div className="logo">
                        <img
                          src={
                            configData
                              ? `${config.localApiUrl}/tenancy/assets/${configData.headerLogo}`
                              : HeaderLogo
                          }
                          alt=""
                        />
                      </div>
                      <div className="signup-btn">
                        <Link to="/login" onClick={ToggleSidebar}>
                          <button
                            title="Sign Up"
                            className="btn-primary sm Sign-btn"

                            // onClick={() => redirectTo("signup")}
                          >
                            Sign Up
                          </button>
                        </Link>
                        <Link to="/login" onClick={ToggleSidebar}>
                          <button
                            title="Log in"
                            className="btn-primary sm login-btn"

                            // onClick={() => redirectTo("login")}
                          >
                            Log in
                          </button>
                        </Link>
                      </div>

                      {/*
                        <div className="menu-bottom-inner">
                        <div className="menu-bottom">
                          <div className="bottom-logo">
                            <img src={HeaderLogo} alt="" />
                          </div>
                          <div className="content">
                            <p>There’s more to love in the app.</p>
                            <button
                              title="Get the app"
                              className="btn-primary sm Get-the"
                            >
                              <div className="apple_pay" />
                              Get the app
                            </button>
                          </div>
                        </div>
                        <p className="loyalty-text">
                          made with
                          <em>
                            <img src={heart} alt="heart icon" />
                          </em>
                          by
                          <Link
                            to="https://sumoeats.co.uk/"
                            onClick={ToggleSidebar}
                          >
                            <img src={footerlogo} alt="" />
                          </Link>
                        </p>
                        </div>
                        */}
                    </div>
                  ) : (
                    <div className="hide-desk">
                      <div className="logo">
                        <img src={usericon} alt="" />
                        <h2>{userData && userData?.customer?.first_name}</h2>
                        <p
                          onClick={() => {
                            setIsopen(false);
                            navigate("/account");
                          }}
                        >
                          Manage account
                        </p>
                      </div>
                      <div className="meno-menu">
                        <ul className="mobo-menu">
                          <li>
                            <Link
                              to="/orders"
                              onClick={() => {
                                setIsopen(false);
                              }}
                            >
                              <img src={ordericon} alt="" />
                              Orders
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="#">
                              <img src={promotionicon} alt="" />
                              Promotions
                            </Link>
                          </li> */}
                          <li>
                            <a
                              onClick={handleShare}
                              style={{ cursor: "pointer" }}
                            >
                              <img src={giftmenuicon} alt="" />
                              Invite Friends
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="signup-btn">
                        <button
                          title="Sign Out"
                          className="btn-primary sm Sign-out-btn"
                          // onClick={() => {
                          //   localStorage.removeItem("userData");
                          //   setIsopen(false);
                          //   setUser("");
                          //   navigate("/");
                          // }}
                          onClick={handleLogout}
                        >
                          Sign Out
                        </button>
                      </div>
                      {/* <div className="menu-bottom-inner">
                        <div className="menu-bottom">
                          <div className="bottom-logo">
                            <img src={HeaderLogo} alt="" />
                          </div>
                          <div className="content">
                            <p>There’s more to love in the app.</p>
                            <button
                              title="Get the app"
                              className="btn-primary sm Get-the"
                            >
                              <div className="apple_pay" />
                              Get the app
                            </button>
                          </div>
                        </div>
                        <p className="loyalty-text">
                          made with
                          <em>
                            <img src={heart} alt="heart icon" />
                          </em>
                          by
                          <Link to="https://sumoeats.co.uk/">
                            <img src={footerlogo} alt="" />
                          </Link>
                        </p>
                      </div> */}
                    </div>
                  )}
                </nav>
              </div>
              <div
                className={`sidebar-overlay ${isOpen == true ? "active" : ""}`}
                onClick={ToggleSidebar}
              ></div>
            </div>
          </div>
        </div>
        {/* )} */}
      </header>
    </>
  );
}

export default Header;
